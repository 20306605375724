.css-55luyf-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
  color: black !important;
}

.css-4mhbuv-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
  color: black !important;
}
.css-1at24i0 {
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
  color: black !important;
}
.css-tmvorb {
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
  color: black !important;
}

.css-1qkubha {
  padding-bottom: 70px !important;
}

.css-fv4unz-MuiList-root {
  margin-bottom: 10px !important;
}
